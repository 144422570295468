<template>
  <div class="flex flex-col bg-white w-screen">
    <pheader></pheader>
    <div class="content grid grid-col-1 lg:grid-cols-2 h-screen w-full">
      <div class="hidden lg:flex  items-center  justify-center bg-loginbackground bg-no-repeat bg-cover">
        <div class="flex flex-col px-16 space-y-3 text-white">
          <h3 class="text-4xl italic">Tizeti Hotspot is an innovative Wi-Fi management platform helping Wi-Fi operators deliver excellent service and optimize value today and as networks scale.</h3>
        </div>

      </div>


      <div class="flex flex-col bg-white md:px-20 px-8 mt-16 pb-10 text-expressgray text-xl ">

        <div class="flex flex-col space-y-9">
          <h1 class="text-express text-3xl font-semibold text-center lg:text-left">Hello {{user.name}},</h1>
            <div class="text-center lg:text-left">
              <!-- <p>We noticed you have an account with multiple access</p> -->
              <p>Kindly select your primary account type to continue</p>
            </div>


            <div v-for="item in user.account_types" :key="item.name">
             <div class="relative selected_parent flex justify-center items-center lg:justify-between rounded-lg border-2 border-gray-300 lg:pl-5 py-4 hover:border-express cursor-pointer"
                @click="transfer(item.name)"
                :class="{ active: options[item.name] }" >
                  <div class="w-full flex flex-col space-y-2 text-center lg:text-left">
                    <p>{{user.email}}</p>
                    <p class="text-express capitalize">{{item.description}}</p>
                  </div>
                <div class="absolute right-1 selected">
                  <i class="fas fa-check-circle text-green-600 text-2xl"></i>
                </div>
           </div>


            </div>


<!-- 

           <div class=" selected_parent flex justify-between border-2 p-5 items-center hover:border-express cursor-pointer "
                @click="transfer('isRetailerActive')"
                :class="{ active: options.isRetailerActive }"
           >
               <div class="flex flex-col space-y-3">
                 <p>williams.chinwa@tizeti.com</p>
                 <p class="text-express">Retailer Account</p>
               </div>

             <div class="selected ">
               <i class="fas fa-check-circle text-green-600 text-2xl"></i>
             </div>
           </div>



          <div class=" selected_parent flex justify-between border-2 p-5 items-center hover:border-express cursor-pointer "
               @click="transfer('isCustomerActive')"
               :class="{ active: options.isCustomerActive }"
          >
            <div class="flex flex-col space-y-3" >
              <p>williams.chinwa@tizeti.com</p>
              <p class="text-express">Customer Account</p>
            </div>

            <div class="selected ">
              <i class="fas fa-check-circle text-green-600 text-2xl"></i>
            </div>
          </div>


          <div class=" selected_parent flex justify-between border-2 p-5 items-center hover:border-express cursor-pointer "
               @click="transfer('isAdminstratorActive')"
               :class="{ active: options.isAdminstratorActive }"
          >
            <div class="flex flex-col space-y-3">
              <p>williams.chinwa@tizeti.com</p>
              <p class="text-express">Administrator Account</p>
            </div>

            <div class="selected ">
              <i class="fas fa-check-circle text-green-600 text-2xl"></i>
            </div>
          </div> -->



          <loading v-if="isLoading" message="Loading please wait"/>

        </div>




      </div>



    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: "SelectAccount",
  data(){
    return {
      isLoading: false,
      // user: null,
      options: {
          customer: false,
          retailer: false,
          rde:false,
          junior_administrator:false,
          super_administrator: false
        }

    }
  },
   computed:{
        ...mapGetters({user :'authentication/getUser'})
  },
  methods:{
    transfer: function (data){
      console.log(data)
        this.$store.dispatch('authentication/setPrimaryAccount',{
            account_type : data
          })

        this.isLoading = true
        Object.keys(this.options).map((value)=>{
          this.options[value] = value === data;
        });

        let vm= this
        setTimeout(function(){
          vm.isLoading = false
        
          if(data==="junior_administrator" ||data==="super_administrator"  || data ==="rde"){
           vm.$router.replace('/administrator/overview')
          }
          else if(data==="customer"){
             vm.$router.replace('/customer/overview')
          }
          else{
           vm.$router.replace('/retailer/overview')
          }

        },4000)
    }
  }
}
</script>

<style scoped>

.selected_parent > .selected{
  opacity: 0;
}

.selected_parent:active > .selected{
  opacity: 100;
}


.active{
  border-color: #3C9FA9;
  opacity: 100;
}

.active> .selected{
  opacity: 100;
}

</style>
