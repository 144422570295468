import axios from 'axios'


export default {
    action_get_hotspot_user_information(context,payload){
        const url = "https://legacy.tizeti.com/geniatechapi/index.php"






    },
    rde_register(context,payload){
        const url = context.getters.baseurl+"/register"
        let body = {
            email: payload.email,
            password:  payload.password,
            phone: payload.phone,
            name: payload.name,
            "type": "rde",
            country:  payload.country
        }
        axios.post(url,body).then((response)=>{
            if(response.status===201){
                payload.toast.success("Created RDE successfully")
            }
            else{
                payload.toast.error('Unable to create RDE')
            }
        }).catch((error)=>{
            console.log("error res" , error)
            payload.toast.error('An error occurred, please try again')
        })
    },
    login(context,payload){
        const url = context.getters.baseurl+"/login"
            let body = {
                phone: payload.phone,
                password: payload.password
            }

            axios.post(url,body).then((response)=>{
                if(response.status===201){

                    console.log("login successsful setBaseUrl setUser ")
                    context.commit('setBaseUrl');
                    context.commit('setUser', response.data)
                  //  console.log("response",response.data)
                    payload.router.replace('/accounts')
                }
                else{
                    payload.toast.error('Incorrect login credentials')
                }
            }).catch((error)=>{
                // console.log("error res" , error)
                if (error.response.status === 401) {
                    payload.toast.error('Incorrect login credentials')
                }else {
                    payload.toast.error('An error occurred, please try again later')
                }


            })
    },

    recoverpassword(context,payload){
            const url = context.getters.baseurl+"/reset-password-by-phone"
            let body = {
                phone: payload.phone
            }
            axios.post(url,body).then((response)=>{
                if(response.status===201){
                    payload.toast.success(response.data.message)
                }
                else{
                    payload.toast.error('Provided details not found in database')
                }
            }).catch((error)=>{
                // console.log("error res" , error)
                payload.toast.error('Too many requests, please try again later')
            })
    },
    setPrimaryAccount(context,payload){
        context.commit('setPrimaryAccount',payload)
    },
    logout(context){
        context.commit('removeUser');
    }
}
