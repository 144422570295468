<template>





  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-3 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" v-if="!isLoading">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-white ">
            <tr class="" >
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider  ">S/N</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider  ">Payment Reference</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider">Product Name</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider">Amount</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider">Currency</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider">Customer Email</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider">Customer Phone</th>
              <th scope="col" class="px-3 py-3 text-left text-base font-light  uppercase tracking-wider">Date</th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">




            <tr v-for="(payment , index) in dummydata" :key="index">
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ index+1 }} </span>
              </td>
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ payment.reference }} </span>
              </td>
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ payment.product_name }} </span>
              </td>
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ payment.amount }} </span>
              </td>
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ payment.currency }} </span>
              </td>
             
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ payment.customer_email.toLowerCase() }} </span>
              </td>
              <td class="px-1 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full  capitalize"> {{ payment.customer_phone }} </span>
              </td>
             



              <td class="px-3 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">{{ payment.created_at}}</div>
                  </div>

                </div>
              </td>

            </tr>

            <!-- More people... -->
            </tbody>
          </table>
        </div>

        <div v-else>
          <loading message="Loading, please wait"></loading>
        </div>



      </div>
    </div>
  </div>

</template>

<script>





export default {


  created() {
    // console.log("testing",  )
    // console.log('Table Component Props',this.props)  //undefined;
  },
  name: "Table",
  emits:["click"],
  data(){
    return {

    }
  },
  props:{
          ['dummydata']:{ type: Array, required: true },
          isLoading:{type: Boolean, default: false}
       },
  methods:{
    // showModel: function (selected_data){
    //   console.log("table showModel triggered",selected_data)
    //   this.$emit('click', selected_data );
    // }
  }
}
</script>

<style scoped>

</style>
