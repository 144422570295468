<template>
  <div class="flex flex-col text-expressgray text-base ">
    <div class="flex flex-row bg-white rounded-lg shadow-lg justify-between p-5">

      <div class="flex flex-col md:flex-row md:space-x-10 py-2 w-full space-y-5 md:space-y-0 justify-between items-center">
        <h1 class="text-lg font-semibold">Customers </h1>
        <div class="flex relative rounded border-2 w-80 border-expressgray  justify-between items-center flex-grow mx-10">
          <input
              type="text"
              class="w-full px-4 py-1 border-0 ml-2 "
              v-model.trim="customer"
              placeholder="Search using Name, Email or Phone Number"
              @keyup.enter="search_customer_live"
          >
          <i class="fas fa-search ml-1 p-2 text-lg hover:text-express hover:scale-100 cursor-pointer" @click="search_customer_live"></i>
        </div>

        <h1 class="text-lg text-black font-semibold">Wallet Balance - {{ wallet_balance }} {{currency}} </h1>
      </div>
     </div>

<!--    Table-->

    <div v-if="!isloading" class="mt-5  rounded-lg shadow-lg">
      <customerstable
          v-if=" customers.length"
          :dummydata="customers"
          @click="showModals"/>

      <div v-else class="mt-5  rounded-2xl shadow-lg h-72 flex flex-col items-center justify-center ">
        <h2 class="text-lg text-center  font-bold text-expressgray">No customer records</h2>
      </div>

    </div>
    <div v-else class="mt-5 rounded-2xl shadow-2xl bg-white w-full  flex flex-col h-52 items-center justify-center space-2 align-middle">
      <loading :message=loadingmessage></loading>
    </div>



    <div v-if="showModel">
      <rechargemodals
          :customer_phone=customer_phone
          :customer_email=customer_email
          :mac_address=mac_address
          :retailer_balance=wallet_balance
          @completeTransaction=completeTransaction
          @cancelTransaction=cancelTransaction
      />
    </div>

  </div>
</template>

<script>
import Table from "@/components/uicomponents/tables/Table";
import rechargemodals from "@/components/uicomponents/models/rechargemodals";
import { mapGetters } from 'vuex'
// import {customers} from "@/fakedata/dummydata";
export default {
  name: "Customers",
   created() {
//
console.log("created Customers.vue ", this.customers)

this.$store.dispatch('retailers/action_retailer_wallet_balance',{
            toast:this.$toast,
            router:this.$router
          });


     },
  components:{
    "customerstable":Table,
    "rechargemodals":rechargemodals
  },
  data(){
    return{
      isloading: false,
      loadingmessage: "",
      mac_address:null,
      customer_phone:null,
      // retailer_balance:null,
      customer_email:null,
      customer: "",
      showModel:false,
      dummydata:[]
    }
  },
   computed:{
        ...mapGetters({
          currency: 'retailers/currency',
          wallet_balance :'retailers/wallet_balance',
          customers: 'retailers/customers'
        }),
  },
  methods:{
    showModals : function (data){


      this.mac_address = data[0]
      this.customer_phone = data[1]
      this.customer_email = data[2]

      this.showModel = !this.showModel

      },

    search_customer_live_after_payment: function (details){
      this.loadingmessage=`Please wait, Searching for ${details} Customer Details`
      this.isloading = true
      //console.log("search_customer_live", "triggered")

      this.$store.dispatch('retailers/action_search_customers',{
        value: details,
        toast:this.$toast,
        router:this.$router
      });



      this.isloading = false
    },

    search_customer_live: async function (){
           this.loadingmessage="Please wait, Searching for Customer Details"
           this.isloading = true
           //console.log("search_customer_live", "triggered")

           this.$store.dispatch('retailers/action_search_customers',{
            value: this.customer,
            toast:this.$toast,
            router:this.$router
          });

          if (this.isNumeric(this.customer)){
            this.customer = "+234"+this.customer.slice(-10)
          }

          if(this.customers.length<=0){
           // this.$toast.error('Customer not found')
          }

          this.isloading = false
    },



    completeTransaction: function (data){
      console.log("completeTransaction", data)

// Todo ,
// Send Notification to Retailer and  Customer
//
      this.isloading = false
      this.showModel = false
     // window.location.reload();
      this.search_customer_live_after_payment(data[0]);

      this.$store.dispatch('retailers/action_retailer_wallet_balance',{
        toast:this.$toast,
        router:this.$router})

      this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
        toast:this.$toast,
        router:this.$router
      })

    },

    cancelTransaction: function (){
      console.log("Customers component got cancelTransaction event" )
      this.isloading = false
      this.showModel = false
    },
    isNumeric: function (num){
        return !isNaN(num)
      },
    delay : ms => new Promise(res => setTimeout(res, ms))

  }
}
</script>

<style scoped>

</style>
