<template>

  <div class="flex flex-col w-scr space-y-4 space-x-0">
<!--    <div class="flex space-x-4 rounded shadow-2xl bg-white p-5 justify-between">-->
<!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643860970/Screenshot_2022-02-03_at_5.02.43_AM.png"  title="Total Revenue " label="75,000,000 NGN"/>-->
<!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643862532/Screenshot_2022-02-03_at_5.28.45_AM.png"  title="Total Hotspot Users " label="2,200,000"/>-->
<!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Active Users " label="2,000,000"/>-->
<!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1645665112/Screenshot_2022-02-24_at_2.11.44_AM.png"  title="Expired Users " label="200,000"/>-->
<!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Active Users " label="2,000,000"/>-->
<!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1645665112/Screenshot_2022-02-24_at_2.11.44_AM.png"  title="Expired Users " label="200,000"/>-->
<!--    </div>-->


    <div class="grid grid-cols-2 gap-4 shadow-2xl bg-white p-5 ">
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Total Revenue Nigeria " :label="total_revenue_nigeria"  />
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Total Revenue Ghana " :label="total_revenue_ghana"/>

      <!--      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643862532/Screenshot_2022-02-03_at_5.28.45_AM.png"  title="Total Hotspot Users " label="2,200,000"/>-->
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Total Nigerian Hotspot Users " :label="total_hotspot_users_nigeria"/>
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Total Ghana Hotspot Users " :label="total_hotspot_users_ghana"/>

      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Total Retailer Sales Nigeria " :label="total_retailer_sales_nigeria"/>
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"  title="Total Retailer Sales Ghana " :label="total_retailer_sales_ghana"/>

    </div>



    <div class="flex flex-col space-x-4 rounded shadow-2xl bg-white p-5 justify-between">

        <h1 class="text-express font-bold text-2xl  mb-10 ">Hotspot User Analysis</h1>
        <div class="flex flex-col ">
             <div class="flex flex-col border-2 rounded p-10 cursor-pointer">
               <h1 class="text-red-500 font-bold text-sm  mb-5 text-right">Nigeria Hotspot Sign-Up Analysis</h1>
  <LineChart
                   chart-id="Nigeria Hotspot Sign-Up Analysis"
                   dataset-id-key=" Nigeria Hotspot Sign-Up Analysis"
                   width="400"
                   height="100"
                   title="Nigeria Hotspot Sign-Up Analysis"
                   :data="merged_hotspot_nigeria_users_breakdown"
                   :reversed="true"
               />

            </div>

            <div class="flex flex-col border-2 rounded p-10 mt-5 cursor-pointer">
              <h1 class="text-red-500 font-bold text-sm  mb-5 text-right"> Ghana Hotspot Sign-Up Analysis </h1>
      <LineChart
                  chart-id="Ghana Hotspot Sign-Up Analysis"
                  dataset-id-key="Ghana Hotspot Sign-Up Analysis"
                  width="400"
                  height="100"
                  title="Ghana Hotspot Sign-Up Analysis"
                  :data="merged_hotspot_ghana_users_breakdown"
                  :reversed="true"
              />


            </div>
          </div>

      <!--          :chart-options="chartOptions"
                    :chart-data="chartData"
                    :chart-id="chartId"-->
      <h1 class="text-express font-bold text-2xl  mb-10 mt-10 ">Revenue Analysis</h1>
      <div class="flex flex-col ">
        <div class="flex flex-col border-2 rounded p-10 cursor-pointer">
          <h1 class="text-red-500 font-bold text-sm  mb-5 text-right"> Nigeria Monthly Revenue Analysis </h1>
        <BarChart
              chart-id=" Nigeria Monthly Revenue Analysis"
              dataset-id-key=" Nigeria Monthly Revenue Analysis"
              width="400"
              height="100"
              title="Nigeria Monthly Revenue Analysis"
              :data="merged_hotspot_nigeria_revenue"
              :reversed="true"
          />

        </div>

        <div class="flex flex-col border-2 rounded p-10 mt-5 cursor-pointer">
          <h1 class="text-red-500 font-bold text-sm  mb-5 text-right">Ghana Monthly Revenue Analysis </h1>
<!--          <column-chart v-if="merged_hotspot_ghana_revenue.length>0" :data="merged_hotspot_ghana_revenue" id="merged_hotspot_ghana_revenue"/>-->
          <BarChart
              chart-id="Ghana Monthly Revenue Analysis "
              dataset-id-key="Ghana Monthly Revenue Analysis "
              width="400"
              height="100"
              title="Ghana Monthly Revenue Analysis "
              :data="merged_hotspot_ghana_revenue"
              :reversed="true"
          />

       </div>

    </div>

      <h1 class="text-express font-bold text-2xl  mb-10 mt-10 bg-white shadow-2xl ">Sales Analysis</h1>
      <div class="flex flex-col merged_hotspot_nigeria_users_breakdown ">
        <div class="flex flex-col border-2 rounded p-10 cursor-pointer">
          <h1 class="text-red-500 font-bold text-sm  mb-5 text-right"> Nigeria Monthly Sales Analysis </h1>
      <BarChart
              chart-id=" Nigeria Monthly Sales Analysis "
              dataset-id-key=" Nigeria Monthly Sales Analysis "
              width="400"
              height="100"
              title="Nigeria Monthly Sales Analysis "
              :data="merged_hotspot_nigeria_sales"
              :reversed="true"
          />

        </div>

        <div class="flex flex-col border-2 rounded p-10 mt-5 cursor-pointer">
          <h1 class="text-red-500 font-bold text-sm  mb-5 text-right">Ghana Monthly Sales Analysis </h1>
<!--          <column-chart v-if="merged_hotspot_ghana_sales.length>0" :data="merged_hotspot_ghana_sales"  id="merged_hotspot_ghana_sales"/>-->

          <BarChart
              chart-id="Ghana Monthly Sales Analysis"
              dataset-id-key="Ghana Monthly Sales Analysis"
              width="400"
              height="100"
              title="Ghana Monthly Sales Analysis"
              :data="merged_hotspot_ghana_sales"
              :reversed="true"
          />
        </div>
      </div>




      <h1 class="text-express font-bold xl:text-2xl md:text-xl text-base  mb-10 mt-10 font-serif">Sales Analysis</h1>
      <div class="flex flex-col ">
        <div class="flex flex-col border-2 rounded p-2 cursor-pointer">
          <h1 class="text-red-500 font-bold text-sm  mb-5 text-right"> Data Plan Breakdown </h1>
<!--          <pie-chart :data="[['Daily Plan 100MB', 0], ['Daily Plan 300MB', 0],['Daily Plan 750MB', 0],['Monthly Plan 4GB', 0]]" ></pie-chart>-->
<!--          <pie-chart v-if="products_breakdown.length>0" :data="products_breakdown" />-->

          <PieChart
              chart-id="Data Plan Breakdown"
                     dataset-id-key="Data Plan Breakdown"
                     :width="150"
                     :height="150"
                     title="Data Plan Breakdown"
                     :data="products_breakdown"
                     :reversed="true"
          />

        </div>

      </div>



    </div>
  </div>


</template>

<script>
import summarycard from "@/components/uicomponents/cards/summarycard";
import BarChart from "@/views/layouts/rde/rdeuicomponent/BarChart";
// import {VueChartkick ,ChartkickCharts }from 'vue-chartkick'
import { mapGetters } from 'vuex'
import LineChart from "@/views/layouts/rde/rdeuicomponent/LineChart";
import PieChart from "@/views/layouts/rde/rdeuicomponent/PieChart";
export default {
  name: "analyticsContainer",
  created() {

    this.$store.dispatch('admins/action_analytics_overview',{
      toast:this.$toast,
      router:this.$router
    });

    this.$store.dispatch('admins/action_hotspot_signup_breakdown',{
      toast:this.$toast,
      router:this.$router
    });
  },
  computed:{
    ...mapGetters({
      overview_analytics: 'admins/overview_analytics',
      hotspot_ghana_users_breakdown: 'admins/hotspot_ghana_users_breakdown',
      hotspot_nigeria_users_breakdown: 'admins/hotspot_nigeria_users_breakdown',
      hotspot_nigeria_sales: 'admins/hotspot_nigeria_sales',
      hotspot_ghana_sales : 'admins/hotspot_ghana_sales',
      hotspot_nigeria_revenue: 'admins/hotspot_nigeria_revenue',
      hotspot_ghana_revenue: 'admins/hotspot_ghana_revenue',
      products_breakdown: 'admins/products_breakdown'
    }),
    total_revenue_nigeria: function(){

      return `₦ ${parseInt(this.overview_analytics['total_revenue_nigeria']).toLocaleString()}`
    },
    total_revenue_ghana: function(){
      return `GH₵ ${parseInt(this.overview_analytics['total_revenue_ghana']).toLocaleString()}`
    },
    total_retailer_sales_nigeria: function(){
      return `₦ ${parseInt(this.overview_analytics['total_retailer_sales_nigeria']).toLocaleString()}`
    },
    total_retailer_sales_ghana: function (){
      return  `GH₵ ${parseInt(this.overview_analytics['total_retailer_sales_ghana']).toLocaleString()}`
    },
    total_hotspot_users_nigeria: function (){
     var total_hotspott_ng =  parseInt(this.overview_analytics['total_hotspot_users_nigeria'])
      return `${total_hotspott_ng.toLocaleString()}`
    },
    total_hotspot_users_ghana: function (){
      var total_hotspott_gh =  parseInt(this.overview_analytics['total_hotspot_users_ghana'])
      return `${total_hotspott_gh.toLocaleString()}`
    },
    merged_hotspot_nigeria_users_breakdown: function(){
      let obj = this.hotspot_nigeria_users_breakdown.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    },
    merged_hotspot_ghana_users_breakdown: function(){
      console.log('this.hotspot_ghana_users_breakdown',this.hotspot_ghana_users_breakdown)
      let obj = this.hotspot_ghana_users_breakdown.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    },
    merged_hotspot_nigeria_revenue: function(){
      let obj = this.hotspot_nigeria_revenue.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    },
    merged_hotspot_ghana_revenue: function(){
      let obj = this.hotspot_ghana_revenue.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    },
    merged_hotspot_nigeria_sales: function(){
      let obj = this.hotspot_nigeria_sales.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    },
    merged_hotspot_ghana_sales: function(){
      let obj = this.hotspot_ghana_sales.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    },
    merged_products_breakdown: function(){
      let obj = this.products_breakdown.reduce(function(acc, val) {
        return Object.assign(acc, val);
      },{});
      return obj
    }

  },
  components:{
    PieChart,
    LineChart,
    "summarycard":summarycard,
    BarChart
    // 'line-chart': ChartkickCharts
  },

}
</script>

<style scoped>

</style>
