export default {
    setSearchedPersonnel(state,payload){
        state.searched_personnel = payload
    },
    setOverviewAnalytics(state,payload){
        state.overview_analytics = payload
    },
    setHotspotNigeriaUsersBreakdown(state,payload){
        state.hotspot_nigeria_users_breakdown  = payload
    },
    setHotspotGhanaUsersBreakdown(state,payload){
        state.hotspot_ghana_users_breakdown  = payload
    },
    setHotspotNigeriaSales(state,payload){
        state.hotspot_nigeria_sales = payload
    },
    setHotspotGhanaSales(state,payload){
        state.hotspot_ghana_sales = payload
    },
    setHotspotNigeriaRevenue(state,payload){
        state.hotspot_nigeria_revenue = payload
    },
    setHotspotGhanaRevenue(state,payload){
        state.hotspot_ghana_revenue = payload
    },
    setProductsBreakdown(state,payload){
        state.products_breakdown = payload
    },
    setRetailerList(state,payload){
        state.retailer_list = payload
    },
    setRetailerListNigeria(state, payload) {
        state.retailer_list_nigeria = payload
    },
    setRetailerListGhana(state, payload) {
        state.retailer_list_ghana = payload
    },
    setRdeList(state,payload){
        state.rde_list = payload
    },
    setDecommissionList(state,payload){
        state.decommission_list = payload
    },
    setRetailersPurchaseList(state,payload){
        state.retailer_purchase_list = payload
    }



}
