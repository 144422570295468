<template>
<TransitionRoot :show="sidebaropened">
<Dialog as="div" @close="$emit('close')" class="fixed inset-y-0 right-0 col-span-1 z-40 lg:hidden">
    <TransitionChild
    enter="transition ease-in-out duration-300 transform"
    enter-from="translate-x-full"
    enter-to="-translate-x-0"
    leave="transition ease-in-out duration-300 transform"
    leave-from="-translate-x-0"
    leave-to="translate-x-full"
    as="template">
  <div class="lg:hidden relative z-10 bg-white px-5 flex flex-col space-y-4 shadow-2xl h-full overflow-y-scroll">
      <button
  class="absolute top-2 right-2 flex items-center justify-center w-6 h-6 rounded-full hover:ring-gray-600 outline-none focus:ring-2 focus:ring-gray-600"
  type="button" value="close sidebar"
  @click="$emit('close')">
      <span class="font-bold text-xl">X</span>
</button>
    <img src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png" alt="Expresswifi" class="w-60">
    <router-link
        to="/retailer/overview/main"
        @click="$emit('close')"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
             <i class="fas fa-home mr-3  text-base"></i> Home
    </router-link>

    <router-link
        to="/retailer/overview/inbox"
        @click="$emit('close')"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
          <div v-if="total_unread>0">
              <i    class="fas fa-exclamation-circle mr-3  text-base inbox relative"></i> Notification ({{total_unread}} Unread)
            </div>
        <div v-else>
           <i
           class="fas fa-exclamation-circle  mr-3  text-base "></i> Notification
        </div>

    </router-link>



    <router-link
        to="/retailer/overview/profile"
        @click="$emit('close')"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
      <i class="fas fa-user-circle mr-3  text-base"></i> Profile
    </router-link>





    <router-link
        to="/retailer/overview/customers"
        @click="$emit('close')">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-user-friends mr-3 text-base"></i>  Customer Sale</p>
    </router-link>

    <div class="flex flex-col  space-y-2">
      <p @click="show_wallet_submenu = !show_wallet_submenu"
         class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150 cursor-pointer">
            <i v-if="show_wallet_submenu"  class="fas fa-caret-down mr-3 text-lg"></i>
            <i v-else  class="fas fa-caret-up mr-3 text-lg"></i>
           Wallet Recharge
      </p>
      <transition name="list">
        <div class="flex flex-col p-2 ml-5 mr-10 w-max" v-if="show_wallet_submenu">
          <router-link to="/retailer/overview/topup"
          @click="$emit('close')">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all animate__fadeInUp duration-150"> <i class="fas fa-shopping-cart mr-3 text-base"></i> Top Up</p>
          </router-link>

          <router-link to="/retailer/overview/topup/history"
          @click="$emit('close')">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all animate__fadeInUp duration-150"> <i class="fas fa-chart-line  mr-3 text-base"></i> Wallet History</p>
          </router-link>

        </div>
      </transition>
    </div>


<!--    salesreport-->

    <!-- <router-link
        to="/retailer/overview/salesreport">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-coins mr-3 text-base"></i> Sales Report</p>
    </router-link> -->

    <router-link
        to="/retailer/overview/report"
        @click="$emit('close')">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-bug mr-3 text-base"></i> Report Issue</p>
    </router-link>


    <!-- <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-globe mr-3 text-base"></i> Relocation </p> -->
<!--    <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-cogs mr-3 text-base"></i> Settings </p>-->


    <router-link
        to="/login"
        @click="$emit('close')">
      <p class="text-red-600 hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-power-off mr-3 text-base"></i> Log Out </p>
    </router-link>



  </div>
    </TransitionChild>

    <TransitionChild
    enter="transition-opacity ease-linear duration-300"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity ease-linear duration-300"
    leave-from="opacity-100"
    leave-to="opacity-0"
    as="template">
  <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-50"></DialogOverlay>
    </TransitionChild>
  </Dialog>
</TransitionRoot>

</template>

<script>
import { mapGetters } from 'vuex'
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay} from '@headlessui/vue'

export default {
  name: "RetailerMenuMobile",
  components: { Dialog, DialogOverlay, TransitionRoot, TransitionChild},
  props: ['sidebaropened'],
  emits: ['close'],
  created(){
    let vm = this

    // setInterval(() => {
        vm.$store.dispatch('retailers/action_retailer_total_unread_messages',{
                toast:this.$toast,
                router:this.$router
              });

        // this.$forceUpdate()

     // console.log("Menu Component")
   // }, 2000);


    this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
                toast:this.$toast,
                router:this.$router
              });
  },
  data(){
    return{
      show_wallet_submenu:false
    }
  },

    computed:{
        ...mapGetters({
          total_unread :'retailers/total_unread',
        })
  },
}
</script>

<style scoped>

.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.inbox::after{
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  right: -5px;
  top: 0px;
  background: hsl(358,62%,52%);
  z-index: 2;
  /*border: 2px solid var(--black-025);*/
  transition: top cubic-bezier(.165, .84, .44, 1) .15s;
}
</style>
