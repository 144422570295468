export default {

    customers_total(state){
        return state.customers_total
    },
    wallet_balance(state){
        return state.wallet_balance
    },
    wallet_history(state){
        return state.wallet_history
    },
    currency(state){
        return state.currency
    },
    sales_volume(state){
        return state.sales_volume
    },
    total_unread(state){
        return state.total_unread
    },
    get_notification_messages(state){
        return state.notification_messages
    },
    customers(state){
        return state.customers
    },
    retailers(state){
        return state.retailers
    },
    computed_retailers(state){
        return state.computed_retailers
    }



}
