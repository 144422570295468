<template>
    <div class="flex flex-col w-full h-full p-5 bg-white  shadow-lg rounded-2xl">
      <h1 class="text-express text-2xl text-center lg:text-left lg:pl-10 font-semibold font-serif"> Notifications</h1>

      <div class="flex flex-col w-full h-full" v-if="!isLoading">

        <div v-for="message in messages.data" :key="message.id">
          <inboxcard
         :id="message.reference"
         :letter="first_letter(message.sender)"
         :from="from_department(message)"
         :subject="message.subject"
         :short_msg=" getWordStr(message.message) "
         :long_msg="message.message"
         :date="getHumanDate(message.created_at)"
         :status="message.status"
         v-on:viewMessage="viewMessage(message)"
        ></inboxcard>
        </div>








      </div>

      <div v-else class="flex h-full w-full text-center items-center justify-center">
        <loading message="Loading, please wait"></loading>
      </div>


      <div v-if="showModel">
        <messagemodals
            :id=message.id
            :from=message.from
            :long_msg=message.long_msg
            :date=message.date
            :status="message.status"
            @completeTransaction=completeTransaction
            @cancelTransaction=cancelTransaction
        />
      </div>






      <div class="max-w-lg mx-auto my-12">
        <pagination :total-pages="totalPages"
                    :total="total"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :has-more-pages="hasMorePages"
                    @pagechanged="showMore">
        </pagination>
      </div>

     </div>
</template>

<script>
import messagemodals from "@/components/uicomponents/models/messagemodals";
import { mapGetters } from 'vuex'
import {getHumanDate} from "@/utils/globalFunctions"

export default {
  name: "Inbox",
   created() {
      //clear all token and logs
      //Total Retailer sales


          this.totalPages = this.messages.to
          this.total = this.messages.total
          this.perPage = this.messages.per_page
          this.currentPage = this.messages.current_page
          this.hasMorePages = this.messages.last_page > this.messages.current_page



  this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
            page: this.currentPage,
            toast:this.$toast,
            router:this.$router
          });



        //   console.log("messsages",this.messages)
      },
  components: {
    "messagemodals": messagemodals
  },

  data(){
    return {
      message:{
        id :"",
        from:"",
        long_msg:"",
        date:"",
        status:""
      },


      totalPages: 1,
      total: 10,
      perPage: 5,
      currentPage :  1,
      hasMorePages: true,
      isLoading:false,
      showModel: false
    }
  },
   computed:{
        ...mapGetters({
          messages :'retailers/get_notification_messages',
        }),
  },
  methods:{
     getHumanDate,
     from_department: function(message){
          return message.sender+ " (" +message.department+ ")"
        },
        first_letter: function(name){
          return name.charAt(0).toUpperCase()
        },
       getWordStr: function (str) {
        return str.split(/\s+/).slice(0,5).join(" ")+"...";
    },
    showMore: function (data){
      this.isLoading = true
      setTimeout( ()=>{
        this.isLoading = false
      },2000)
      if(data <= 0){
        data = 1
      }
      this.currentPage = data
      // console.log('showMore currentPage', this.currentPage)
       this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
            page: this.currentPage,
            toast:this.$toast,
            router:this.$router
          });

    },
    viewMessage: function (data){
     // console.log('viewMessage triggered ', data)
       this.$store.dispatch('retailers/action_update_unread_messages',{
            reference: data.reference,
            status: 'Read',
            toast:this.$toast,
            router:this.$router
          });

      this.message.id = data.reference
      this.message.date = data.created_at
      this.message.from =   `${data.sender} (${data.department})`
      this.message.long_msg = data.message
      this.message.status = data.status

      this.isLoading = true
      setTimeout( ()=>{
        this.isLoading = false
        this.showModel = true
      },2000)


    },
    completeTransaction: function (){
      this.showModel = false
     // console.log("completeTransaction triggered")
       this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
            page: this.currentPage,
            toast:this.$toast,
            router:this.$router
          });
    },
    cancelTransaction: function (){
    //  console.log("cancelTransaction triggered")
      this.showModel = false
    }
    }

}
</script>

<style scoped>

</style>
