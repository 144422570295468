<template>
<div class="p-2 flex flex-col">
  <p class="text-expressgray mb-5 text-2xl lg:pl-10 text-center lg:text-left mt-5 font-semibold font-serif">Payment History</p>
  <div class="flex flex-col w-full p-2 rounded shadow-lg bg-white ">

    <div class="flex mt-3 mb-3 ml-2">
      <div class="flex relative rounded border-2  justify-between items-center mb-3 h-10 ">
        <input
            type="text"
            class="md:w-80  px-4 py-1 border-0  "
            v-model.number.trim="reference"
            placeholder="Search by Payment Reference"
        >
        <i class="fas fa-search ml-3 p-2 text-lg hover:text-express hover:scale-100 cursor-pointer" @click="fetchMore"></i>
      </div>

    </div>

     <div class="grid grid-col-1 md:grid-cols-3 space-y-4 md:space-y-0 md:space-x-5 items-center ml-2">
       <div class="flex space-x-3 items-center text-sm text-gray-400">
         <span>Start</span>
         <Datepicker
                 class="text-gray-400"
                 v-model="from"
                :format="format"
                autoApply>
         </Datepicker>
         <!-- {{from}} -->
       </div>
       <div class="flex space-x-4 items-center text-sm">
         <span>End</span> <Datepicker v-model="to" :format="format" autoApply  />
          <!-- {{to}} -->
       </div>
       <button class="bg-green-700 text-white px-2 py-1 hover:bg-expressgray rounded h-8 text-sm w-28" @click="fetchMore">Apply</button>
     </div>

    <payment-history-table class="p-5 mt-5 " :dummydata="wallet_history"  :isLoading="isLoading" />
    <div class="max-w-lg mx-auto my-12">
      <!-- <pagination :total-pages="totalPages"
                  :total="total"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :has-more-pages="hasMorePages"
                  @pagechanged="showMore">
      </pagination> -->
    </div>
  </div>








</div>
</template>

<script>
import PaymentHistoryTable from "@/components/uicomponents/tables/PaymentHistoryTable";
import { ref } from 'vue';
// import {paymenthistory} from "@/fakedata/dummydata";
// import summarycard from "@/components/uicomponents/cards/summarycard";
import { mapGetters } from 'vuex'
export default {

  name: "PaymentHistory",
   setup() {
        console.log("setup called")
        var startDate = new Date();
            startDate.setFullYear(startDate.getFullYear() - 1)

      //  const from = ref(new Date().toISOString().slice(0,10));
      const from = startDate.toISOString().slice(0,10);
        const to = ref(new Date().toISOString().slice(0,10));
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return {
            from,to,format
        }
    },
  created(){
    // this.from = new Date()
    // this.to = new Date(new Date().setDate(this.from.getDate() + 7));

    // console.log('from',this.from)
    // console.log('to',this.to)


        this.$store.dispatch('retailers/action_fetch_wallet_history',{
            reference: this.reference,
            from: this.from,
            to: this.to,
            toast:this.$toast,
            router:this.$router
          });

          //console.log("wallet_history",this.wallet_history)
          // console.log("getUser",this.getUser)
  },
    computed:{
        ...mapGetters({
          wallet_history: 'retailers/wallet_history',
          getUser: 'authentication/getUser',
        })
  },
  components:{
    "payment-history-table":PaymentHistoryTable,
  // 'summarycard':summarycard
  },
  data(){
    return  {
      // paymenthistory,
      reference: '',
      page: 1,
      currentPage: 1,
      perPage:2,
      total: 1000,
      totalPages: 20,
      hasMorePages:true,
      isLoading:false
    }
  },
  watch: {
    from(newValue){
      newValue =  ref(new Date(newValue).toISOString().slice(0,10))
       this.from = newValue._rawValue
        //  console.log('newValue',newValue)
        //  console.log('newvalue_',newValue._rawValue)
        // console.log('oldValue',oldValue)
    } ,
    to(newValue){
       newValue = ref(new Date(newValue).toISOString().slice(0,10))
      this.to =  newValue._rawValue
        // console.log('newValue',newValue)
        // console.log('oldValue',oldValue)
    }
  },
  methods:{
    showMore: function (data){
      this.isLoading = true
      setTimeout( ()=>{
        this.isLoading = false
      },2000)
        this.currentPage = data
    },
    fetchMore: function(){
       this.isLoading=true
       this.$store.dispatch('retailers/action_fetch_wallet_history',{
            reference: this.reference,
            from: this.from,
            to: this.to,
            toast:this.$toast,
            router:this.$router
          });

setTimeout( ()=>{
        this.isLoading = false
      },2000)


    }
  }
}
</script>

<style scoped>

</style>
