export default {

    searched_personnel(state){
        return state.searched_personnel
    },
    overview_analytics(state){
        return state.overview_analytics
    },
    hotspot_nigeria_users_breakdown(state){
        return state.hotspot_nigeria_users_breakdown
    },
    hotspot_ghana_users_breakdown(state){
        return state.hotspot_ghana_users_breakdown
    },
    hotspot_nigeria_sales(state){
        return state.hotspot_nigeria_sales
    },
    hotspot_ghana_sales(state){
        return state.hotspot_ghana_sales
    },
    hotspot_nigeria_revenue(state){
        return state.hotspot_nigeria_revenue
    },
    hotspot_ghana_revenue(state){
        return state.hotspot_ghana_revenue
    },
    products_breakdown(state){
        return state.products_breakdown
    },
    retailer_list(state){
        return state.retailer_list
    },
    retailer_list_ghana(state){
      return state.retailer_list_ghana
    },
    retailer_list_nigeria(state){
      return state.retailer_list_nigeria
    },
    rde_list(state){
     return state.rde_list
    },
    decommission_list(state){
        return state.decommission_list
    },
    retailer_purchase_list(state){
        return state.retailer_purchase_list
    }




}
