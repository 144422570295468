<template>
  <div class="bg-white flex flex-col p-10  shadow-2xl ">
       <h1 class="text-2xl mb-5 text-left">Showing results for: {{search_value}}</h1>
      <table-lite
          :has-checkbox="false"
          :is-loading="loading"
          :is-re-search="isReSearch"
          :columns="columns"
          :rows="searched_personnel"
          :total="searched_personnel.length"
      ></table-lite>

  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import {mapGetters} from "vuex";

export default {
 name: "SearchView",
  components: { TableLite },
  created(){
   // if(this.search_value !== ''){
   // }
  },
  props:["search_value"],
  data(){
    return {
      loading:false,
      isReSearch:false,
      columns: [
        {
          label: "Name",
          field: "name",
          width: "5%",
          sortable: false,
          isKey: false,
        },
        {
          label: "Email",
          field: "email",
          width: "10%",
          sortable: false,
        },
        {
          label: "Mobile",
          field: "phone",
          width: "5%",
          sortable: false,
        },
        {
          label: "Business Name",
          field: "business_name",
          width: "5%",
          sortable: false,
        },
        {
          label: "Wallet Balance",
          field: "wallet",
          width: "15%",
          sortable: false,
        },
        {
          label: "Account Type",
          field: "type_name",
          width: "5%",
          sortable: false,
        },
        {
          label: "Account Status",
          field: "status",
          width: "5%",
          sortable: false,
        },
        {
          label: "Account Type",
          field: "type_name",
          width: "5%",
          sortable: false,
        },
        {
          label: "Registered",
          field: "created_at",
          width: "5%",
          sortable: false,
        },
        {
          label: "Address",
          field: "address",
          width: "40%",
          sortable: false,
        },

      ],
      totalRecordCount: 2,
      sortable: {
        order: "id,name",
        sort: "asc",
      },

    }
 },
  computed:{
    ...mapGetters({
      user: 'authentication/getUser',
      searched_personnel: 'admins/searched_personnel'
      // products: 'products/products'
    }),
  }
}
</script>

<style>

</style>
