<template>
    <div class="flex flex-col p-5"> 
        <h1 class="text-expressgray text-center xl:text-left text-2xl font-semibold font-serif">Purchase History</h1>
        <div  class="grid grid-cols-1 gap-4 mt-5 mb-5"> 
            <!-- <div v-for="product in products" :key="product.id"> 
                 <div class="flex flex-col p-8 bg-white rounded-2xl shadow-2xl h-full">
                    <h2 class="text-base mb-6">{{product.name}}  </h2>
                    <div class="flex justify-between mt-4 text-sm"> 
                            <span>Category: {{ product.category }} </span>
                            <span class="text-green-500 text-xl">{{product.amount}} {{product.currency}}</span>

                    </div>
                 </div>
             </div> -->

             <customerPaymentTable :dummydata="payment_history" :isLoading="isLoading"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerPaymentHistoryTable from '@/components/uicomponents/tables/CustomerPaymentHistoryTable'

export default {
    name:"CustomerPaymentHistory",
    created() {

        this.isLoading = true
        const phone = this.user.phone
        this.$store.dispatch('customers/action_get_customer_payment_history',{
          phone: phone,
         toast:this.$toast,
         router:this.$router
           }); 

      setTimeout(()=>{
           this.isLoading = false
      },5000)
       
        // console.log('payment_history', this.payment_history)

    },
    data(){
      return {
        isLoading:false
      }
    },
    components:{
      "customerPaymentTable" : CustomerPaymentHistoryTable
    },
      computed:{
        ...mapGetters({
          user: 'authentication/getUser',
          payment_history: 'customers/payment_history'
         // products: 'products/products'
        }),
  },
    
}
</script>
<style scoped>

</style>