<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 align-middle my-8 mb-50 ">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title"> Create New RDE </h3>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Email</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.email" autocomplete="email"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Password</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.password"/>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Confirm Password</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.comfirm_password"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Phone</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.phone" placeholder="e.g 234XXXXXXXXXX"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Name</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.name" placeholder="Full Name"/>
              </div>
            </div>



            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Account Type</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model.trim="form.type" class="border-2 rounded p-3 w-full">
                  <option value="rde">RDE</option>
                </select>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Country</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model.trim="form.country" class="border-2 rounded p-3 w-full">
                  <option value="Nigeria">Nigeria</option>
                  <option value="Nigeria">Ghana</option>
                </select>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeRDEAccount">Create RDE Account</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelRDEAccount">Close</button>
            </div>




          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "add_retailer_model",
  props: [],
  emits:["completeRDEAccount","cancelRDEAccount"],
  data(){
    return{
      form:{
        email:null,
        password:null,
        comfirm_password:null,
        phone:null,
        name:null,
        type:'rde',
        country:null,
      },
    }
  },
  watch:{
    // 'form.country' : {
    //   handler(newVal, oldVal){  // here having access to the new and old value
    //     // do stuff
    //     // console.log("country", newVal)
    //     if(newVal==="Nigeria"){
    //       this.states_option = this.states.Nigeria
    //       this.zones_option = this.zones.Nigeria
    //     }else{
    //       this.states_option = this.states.Ghana
    //       this.zones_option = this.zones.Ghana
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },




  methods: {
    emailValidate : function (email) {
      const regexp= /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
      return regexp.test(email);
    },
    phoneValidate: function (phone){
      if(phone == null) return false
      const regexp= /234[0-9]{10}/;
      const regexp_ghana =  /233[0-9]{9}/;
      return regexp.test(phone) || regexp_ghana.test(phone) ;
    },
    resetForm: function(){
      for (var item in this.form)
      {
        this.form[item] = null
      }
},
    completeRDEAccount()  {
      if(
          (this.form.email !== null ) &&
          (this.form.phone !== null ) &&
          (this.form.name !== null ) &&
          (this.phoneValidate(this.form.phone)) &&
          (this.emailValidate(this.form.email)) &&
          (this.form.comfirm_password !== null ) &&
          (this.form.name.length > 3 ) &&
          (this.form.comfirm_password === this.form.password) &&
          (this.form.comfirm_password.length >= 5) &&
          (this.form.country !== null )
      )
      {
        console.log('emit completeRDEAccount')
        this.$emit('completeRDEAccount', [this.form])
      }else{
          console.log('missing fields')
        // console.log( (this.form.email !== null ) &&
        //     (this.form.phone !== null ) &&
        //     (this.form.name !== null ) &&
        //     (this.phoneValidate(this.form.phone)) &&
        //     (this.emailValidate(this.form.email)) &&
        //     (this.form.comfirm_password !== null ) &&
        //     (this.form.name.length > 3 ) &&
        //     (this.form.comfirm_password === this.form.password) &&
        //     (this.form.comfirm_password.length >= 5) &&
        //     (this.form.country !== null ) )

        console.log('this.form.email',this.form.email !== null  )
        console.log('this.form.phone',this.form.phone !== null  )
        console.log('this.form.name',this.form.name !== null  )
        console.log('this.phoneValidate(this.form.phone)',this.phoneValidate(this.form.phone)  )
        console.log('this.emailValidate(this.form.email)', this.emailValidate(this.form.email)  )
        console.log('this.form.comfirm_password',this.form.comfirm_password !== null  )
        console.log('this.form.name.length > 3',this.form.name.length > 3 )
        console.log('this.form.comfirm_password === this.form.password',this.form.comfirm_password === this.form.password )
        console.log('this.form.comfirm_password.length >= 5',this.form.comfirm_password.length >= 5 )
        console.log('this.form.country !== null',this.form.country !== null )

          let fields = ''
          if(!this.phoneValidate(this.form.phone))
            fields = fields+'Incorrect Phone Number Pattern, '
          if(!this.emailValidate(this.form.email)){
            fields = fields+'Email, '
          }
          if(!this.form.name.length >= 3){
            fields = fields+'Full Name is too short, '
          }
          if( ( !(this.form.comfirm_password === this.form.password))){
            fields = fields+' Password and Confirm Password does not match'
          }
        if( ( !(this.form.comfirm_password.length >= 5))){
          fields = fields+' Password must be at least 5 Characters'
        }
          this.$toast.error(`Error Missing fields:  ${fields}`)
      }
    },
    cancelRDEAccount: function (e) {
      this.$emit('cancelRDEAccount')
      this.resetForm()
    }
  }


}
</script>

<style scoped>

</style>
