<template>
  <div class="flex flex-col p-5">
    <p class="text-2xl text-expressgray text-center lg:text-left lg:pl-10 font-semibold font-serif">  Customer Overview</p>

    <div class="grid grid-cols-1  gap-10 mt-5">

        <div class="bg-white text-expressgray p-10 rounded-2xl shadow-xl">
             <p class="text-xl text-expressgray text-center md:text-left font-semibold font-serif">  My Information</p>
              <div class="grid grid-col-1 md:grid-cols-2 text-center md:text-left gap-8 mt-5 mb-5">
                <titlecard title="Email" :description="user.email" colour="text-red-400"/>
                <titlecard title="Phone Number" :description="user.phone" colour="text-expressgray"/>
             </div>
        </div>


        <div class="text-expressgray p-5 ">
          <div  v-if="customers.length>0" class="flex flex-col p-5 ">
            <div v-for="customer, index in customers" :key="index" class=" rounded-4xl shadow-xl p-5">
                  <div v-if="show_change_device_model">
                    <adddevicelabel
                        :mac_address=selected_mac
                        @completeTransaction=completeTransaction
                        @cancelTransaction=cancelTransaction
                    />
                  </div>

                  <p class="text-xl text-expressgray text-center md:text-left font-semibold font-serif mt-4 ">  Account Summary</p>
                  <div class="grid text-center md:text-left grid-col-1 md:grid-cols-2 gap-8 mt-5 mb-5">

                    <input type="hidden"  :value="get_customer_expiration_date(customer.mac_address)">

                    <titlecard title="Mac Address" :description="customer.mac_address" colour="text-expressgray"/>
                    <titlecard title="Registered Email" :description="customer.email" colour="text-red-400"/>
                    <titlecard title="Remaining Data Balance" :description="customer.data_balance" colour="text-green-400"/>
                    <titlecard title="Plan Expiration Date" :description="mac_expiration[customer.mac_address]" colour="text-red-400"/>
                    <titlecard title="Total Upload" :description="customer.usage[0].total_upload_mb" colour="text-green-400"/>
                    <titlecard title="Total Download" :description="customer.usage[0].total_download_mb" colour="text-red-400"/>
                    <titlecard title="" description="" colour=""/>
                    <titlecard title="" description="" colour=""/>
                    <titlecard title="Device Label" :description="customer.device_label" colour="text-red-500"/>
                    <button class="text-white  bg-green-500 w-full p-4" @click="change_device_label(customer.mac_address)" > Change Device Label</button>
               </div>

            </div>

              </div>
                  <div v-else class="flex flex-col p-5">
                    <p class="text-lg text-expressgray">No Device found</p>
                 </div>
             </div>
           </div>









  </div>




</template>

<script>

import TitleContentCard from "@/components/uicomponents/cards/TitleContentCard";
import { mapGetters } from 'vuex'
import {getHumanDate} from "@/utils/globalFunctions"
import add_device_label from "@/components/uicomponents/models/add_device_label";

export default {
  name: "CustomerMainOverview.vue",
   created() {
      const email = this.user.email
      const phone  = this.user.phone

       // total lifetime sales value
      this.$store.dispatch('customers/action_search_customers',{
         value : phone,
         toast:this.$toast,
         router:this.$router
      });

    },

  components:{
    'titlecard': TitleContentCard,
    'adddevicelabel': add_device_label
  },
    computed:{
        ...mapGetters({
          user: 'authentication/getUser',
          customers: 'customers/customers'
        }),
  },
  data(){
    return{
      show_change_device_model: false,
      mac_expiration:{},
      selected_mac: ''
    }
  },
       methods: {
         change_device_label(mac_address){
           this.selected_mac = mac_address
           this.show_change_device_model =true
         },
            getHumanDate,
          compareDates(date) {
              var UserDate = date;
              var ToDate = new Date();
              if (new Date(UserDate).getTime() <= ToDate.getTime()) {
                   return ['Expired','text-red-400'];
                }
              return ['Active','text-green-400'];
              },
           get_customer_balance(username){

           },
        async get_customer_expiration_date(username){
           var myHeaders = new Headers();
           myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

           var urlencoded = new URLSearchParams();
           urlencoded.append("action", "hotspot_fetch_expiration_date");
           urlencoded.append("username", username);

           var requestOptions = {
             method: 'POST',
             headers: myHeaders,
             body: urlencoded,
             redirect: 'follow'
           };
           await fetch("https://legacy.tizeti.com/geniatechapi/index.php", requestOptions)
               .then(response => response.text())
               .then(result => {
                 result = JSON.parse(result)
                 this.mac_expiration[username] =  result.message
               })
               .catch(error => console.log('error', error));
         },
         completeTransaction(data){
             this.show_change_device_model = false
             const new_label  = data[0].new_label
           this.$store.dispatch('customers/action_change_label',{
             mac_address : data[1],
             new_name : new_label,
             toast:this.$toast,
             router:this.$router
           });
              console.log('completed transaction',data[0].new_label)
         },
         cancelTransaction(){
              this.show_change_device_model = false
           console.log('cancelTransaction transaction')
         }

        },

}
</script>

<style scoped>

</style>
