<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 align-middle my-8 mb-50 ">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title"> Create New Retailer </h3>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Email</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.email" autocomplete="email"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Phone</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.phone" placeholder="e.g 234XXXXXXXXXX"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Name</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.name" placeholder="Full Name"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Business Name</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.business_name" placeholder="Business Name"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Business Address</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <textarea v-model.trim="form.address"  class="border-2 rounded p-3 w-full" cols="30" rows="10"></textarea>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Country</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model.trim="form.country" class="border-2 rounded p-3 w-full">
                  <option value="Nigeria">Nigeria</option>
                  <option value="Ghana">Ghana</option>
                </select>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">State</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model="form.state" class="border-2 rounded p-3 w-full">
                  <option v-for="(item,index) in states_option" v-bind:value="item" :key="index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Zone</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model="form.zone" class="border-2 rounded p-3 w-full">
                  <option v-for="(item,index) in zones_option" v-bind:value="item" :key="index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Latitude</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="number" step=".0001" class="border-2 rounded p-3 w-full" v-model.trim="form.latitude" placeholder="Latitude"/>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Longitude</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="number" step=".0001" class="border-2 rounded p-3 w-full" v-model.trim="form.longitude" placeholder="Longitude"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeTransaction">Create Retailer Account</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction">Close</button>
            </div>




          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "add_retailer_model",
  props: ['staff_email'],
  emits:["completeTransaction","cancelTransaction"],
  data(){
    return{
      states_option:[],
      zones_option:[],
      states:{
        Nigeria: [
          "Abia",
          "Adamawa",
          "Akwa Ibom",
          "Anambra",
          "Bauchi",
          "Bayelsa",
          "Benue",
          "Borno",
          "Cross River",
          "Delta",
          "Ebonyi",
          "Edo",
          "Ekiti",
          "Enugu",
          "FCT - Abuja",
          "Gombe",
          "Imo",
          "Jigawa",
          "Kaduna",
          "Kano",
          "Katsina",
          "Kebbi",
          "Kogi",
          "Kwara",
          "Lagos",
          "Nasarawa",
          "Niger",
          "Ogun",
          "Ondo",
          "Osun",
          "Oyo",
          "Plateau",
          "Rivers",
          "Sokoto",
          "Taraba",
          "Yobe",
          "Zamfara"
        ],
        Ghana:[
            "Accra",
            "Kumasi",
            "Tamale",
            "Sekondi",
            "Obuase",
            "Tema",
            "Cape Coast",
            "Koforidua",
            "Ho",
            "Wa",
            "Bawku",
            "Sunyani",
            "Bolgatanga",
            "Aflao",
            "Nkawkaw",
            "Hohoe",
            "Winneba",
            "Berekum",
            "Techiman",
            "Sefwi Wiawso",
            "Goaso",
            "Dambai",
            "Nalerigu",
            "Damongo"
        ]
      },
      zones:{
        Nigeria:[
            "Unknown",
          "Abeokuta North",
          "Abeokuta South",
          "Abule Egba",
          "ABULOMA",
          "ADO RD",
          "Agege",
          "AGO PALACE",
          "AGUDA -SURULERE",
          "AGURA",
          "AirportRd",
          "AJAH",
          "Ajao",
          "AKARIGBO",
          "Akoka",
          "Alapere",
          "Alausa",
          "ALLEN AVENUE",
          "Amuwo-Odofin",
          "Amuwo-Odofin Festac",
          "Anthony",
          "Arepo",
          "AWOYAYA",
          "Badore",
          "BENIN ZONE",
          "Berger",
          "Bodethomas",
          "DLINE",
          "Eastern Bypass",
          "EDO SCHOOL PROJECT",
          "EGBEDA",
          "Eleganza",
          "Eti-Osa",
          "FADEYI",
          "Gbagada",
          "GRA,SABO",
          "Ifako-Ijaiye",
          "IJESHA",
          "Ikeja",
          "Ikorodu",
          "IKOSI KETU",
          "IKOTA",
          "ILUPEJU",
          "ISHERI",
          "Isola",
          "IWAYA",
          "Iyana-Ipaja",
          "Jakande",
          "Kosofe",
          "Lagos Island",
          "Lagos Mainland",
          "lawanson",
          "Mafolukun-oshodi",
          "Magboro",
          "MAGODO",
          "Maruwa",
          "MARYLAND",
          "MILE12",
          "Mushin",
          "OGBA",
          "Ogbunabali",
          "Ogudu",
          "Ojodu Begger",
          "OJOTA",
          "OKOTA",
          "OLUSOSUN",
          "Opebi",
          "Oregun",
          "Orile",
          "Oshodi",
          "OWORO",
          "PAPA AJAO",
          "Rumuogba",
          "Yaba",
          "Satellite",
          "Shagamu",
          "Shomolu",
          "Shongotedo",
          "Surulere",
          "TRANS AMADI",
          "Westwood",
          "Woji"
        ],
        Ghana:[
          "Adenta",
          "Botwe",
          "East Legon",
          "Ivy",
          "kaiser",
          "lapaz",
          "Madina",
          "menpasem",
          "SPINTEX"

        ]
      },
      form:{
        email:null,
        password:"admin",
        phone:null,
        business_name: null,
        address:null,
        type:'retailer',
        country:null,
        state:"Lagos",
        city: null,
        zone: null,
        latitude: null,
        longitude: null,
        balance:0,
        status: "Active",
        facebookid: this.staff_email
      },


    }
  },
  watch:{
    'form.country' : {
      handler(newVal, oldVal){  // here having access to the new and old value
        // do stuff
        // console.log("country", newVal)
        if(newVal==="Nigeria"){
          this.states_option = this.states.Nigeria
          this.zones_option = this.zones.Nigeria
        }else{
          this.states_option = this.states.Ghana
          this.zones_option = this.zones.Ghana
        }
      },
      deep: true,
      immediate: true
    }
  },




  methods: {
    emailValidate : function (email) {
      const regexp= /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
      return regexp.test(email);
    },
    phoneValidate: function (phone){
      const regexp= /234[0-9]{10}/;
      const regexp_ghana =  /233[0-9]{9}/;
      return regexp.test(phone) || regexp_ghana.test(phone) ;
    },
    resetForm: function(){
      for (var item in this.form)
      {
        this.form[item] = null
      }
},
    completeTransaction()  {
      this.form.city = this.form.zone
      if(
          (this.phoneValidate(this.form.phone)) &&
          (this.emailValidate(this.form.email)) &&
          (this.form.business_name.length > 3 ) &&
          (this.form.address.length > 3 ) &&
          (this.form.country !== null ) &&
          (this.form.city !== null ) &&
          (this.form.zone !== null ) &&
          (this.form.latitude !== null ) &&
          (this.form.longitude !== null )
      ){
        this.$emit('completeTransaction', [this.form])
      }else{
          console.log('missing fields')
          let fields = ''
          if(!this.phoneValidate(this.form.phone))
            fields = fields+'Incorrect Phone Number Pattern, '
          if(!this.emailValidate(this.form.email)){
            fields = fields+'Email, '
          }
          if(!this.form.business_name.length > 3){
            fields = fields+'Business Name too short, '
          }
          if(!this.form.address.length > 3){
            fields = fields+'Address too short, '
          }
          this.$toast.error(`Error Missing fields:  ${fields}`)
      }
    },
    cancelTransaction: function (e) {
      this.$emit('cancelTransaction')
      this.resetForm()
    }
  }


}
</script>

<style scoped>

</style>
